exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-500-js": () => import("./../../../src/pages/500.js" /* webpackChunkName: "component---src-pages-500-js" */),
  "component---src-pages-help-index-js": () => import("./../../../src/pages/Help/index.js" /* webpackChunkName: "component---src-pages-help-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-[id]-js": () => import("./../../../src/pages/Menu/[id].js" /* webpackChunkName: "component---src-pages-menu-[id]-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/News/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-store-js": () => import("./../../../src/pages/Store.js" /* webpackChunkName: "component---src-pages-store-js" */),
  "component---src-templates-about-template-js": () => import("./../../../src/templates/about-template.js" /* webpackChunkName: "component---src-templates-about-template-js" */),
  "component---src-templates-help-template-js": () => import("./../../../src/templates/help-template.js" /* webpackChunkName: "component---src-templates-help-template-js" */),
  "component---src-templates-news-template-js": () => import("./../../../src/templates/news-template.js" /* webpackChunkName: "component---src-templates-news-template-js" */)
}

